import React from "react";
import { Link } from "react-router-dom";
import "./header.css";
import { FaUserLock } from "react-icons/fa";
import { Menu, Dropdown } from "antd";
import useAuth from "./../../data/useAuth";
import useAdmin from "./../../data/useAdmin";

const Header = ({ auth }) => {
  const { logoutAction } = useAuth();
  const { profile } = useAdmin();
  const { user, isAdmin } = auth;

  // console.log(profile);

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => logoutAction()}>
        <div>Logout</div>
      </Menu.Item>
      {/* <Menu.Item key="2">
        <div>Change Password</div>
      </Menu.Item> */}
    </Menu>
  );

  return (
    <>
      <div className="logo">
        <Link to="/">
          {profile?.companyName === "" ? "ABMS" : profile?.companyName}
        </Link>
      </div>

      <Dropdown overlay={menu} placement="bottomRight" arrow>
        <div className="user">
          <span style={{ fontSize: 15, paddingRight: 10 }}>
            {user?.name}
            {isAdmin ? "(Admin)" : "(Staff)"}
          </span>
          <FaUserLock />
        </div>
      </Dropdown>
    </>
  );
};

export default Header;
