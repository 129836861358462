import React, { useState, useEffect, lazy, Suspense } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Layout from "./component/Layout/Layout";
import Signin from "./screen/auth/login";
import Signup from "./screen/auth/register";

import { useQuery } from "react-query";
import useAuth from "./data/useAuth";
const AttendanceReport = lazy(() => import("./screen/reports/attendance-report/AttendanceReport"));
const OutletLeadger = lazy(() => import("./screen/reports/outlet-leadger/OutletLeadger"));
//Routes
const Dashboard = lazy(() => import("./screen/Dashboard/Dashboard"));
const Outlets = lazy(() => import("./screen/outlet/Outlets"));

const Performance = lazy(() => import("./screen/performance/Performance"));
const Vault = lazy(() => import("./screen/vault/vault/Vault"));
const Register = lazy(() => import("./screen/vault/daily-register/Register"));
const Sundry = lazy(() => import("./screen/vault/sundry-ledger/Sundry"));
const Commission = lazy(() => import("./screen/commission/Commission"));
const ProfitDistribution = lazy(() =>
  import("./screen/ProfitDistribution/ProfitDistribution")
);
const Income = lazy(() => import("./screen/voucher/income/Income"));
const Expense = lazy(() => import("./screen/voucher/expense/Expense"));
const AddNewEmp = lazy(() => import("./screen/employee/add-new/AddNewEpm"));
const UpdateStaff = lazy(() =>
  import("./screen/employee/update-staff/UpdateStaff")
);
const EmpList = lazy(() => import("./screen/employee/list/EmpList"));
const EmpInfo = lazy(() => import("./screen/employee/list/EmpInfo"));
const Provident = lazy(() => import("./screen/employee/provident/Provident"));
const Attendance = lazy(() =>
  import("./screen/employee/attendance/Attendance")
);
const SalarySheet = lazy(() => import("./screen/payroll/sheet/SalarySheet"));
const Generator = lazy(() => import("./screen/payroll/generator/Generator"));
const Distribute = lazy(() => import("./screen/payroll/distribute/Distribute"));
const Shareholder = lazy(() =>
  import("./screen/partners/shareholder/Shareholder")
);
const ShareholderDetails = lazy(() =>
  import("./screen/partners/shareholder/TransectionDetails")
);
const Investor = lazy(() => import("./screen/partners/investor/Investor"));
const Investment = lazy(() => import("./screen/partners/investor/Investment"));
const InvestmentTransection = lazy(() =>
  import("./screen/partners/investor/InvestmentTransection")
);
const AllContacts = lazy(() => import("./screen/all-contacts/AllContacts"));
const RegisterReport = lazy(() =>
  import("./screen/reports/register/RegisterReport")
);
const Ale = lazy(() => import("./screen/reports/asset-liability-equity/Ale"));
const DWperformance = lazy(() =>
  import("./screen/reports/performance/DWperformance")
);
const IncomeExpense = lazy(() =>
  import("./screen/reports/income-expense/IncomeExpense")
);
const NewUser = lazy(() => import("./screen/settings/new-user/NewUser"));
const Opening = lazy(() => import("./screen/settings/opening/Opening"));
const Payments = lazy(() => import("./screen/settings/Payments/Payments"));
const Assets = lazy(() => import("./screen/settings/assets/Assets"));
const Misc = lazy(() => import("./screen/settings/misc/Misc"));

const App = () => {
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { authQueryErrorHandler } = useAuth();
  const { data: authData } = useQuery("auth", authQueryErrorHandler);

  const [outletId, setOutlet] = useState(null);
  const [userState, setUserState] = useState({
    isAdmin: false,
    permissions: [],
    user: null,
  });

  useEffect(() => {
    if (authData !== undefined) {
      if (authData?.isAuthenticated) {
        setUserState((p) => ({
          ...p,
          isAdmin: authData?.isAdmin,
          permissions: authData?.permissions ? authData?.permissions : [],
          user: authData?.user,
        }));
        setIsAuthenticated(true);


        // If Not Admin 
        if (!authData.isAdmin){
          setOutlet(authData.outletId);
        }
      }
    }
  }, [authData]);

  // History Remember
  useEffect(() => {
    history.listen((location, action) => {
      if (location.pathname !== "/") {
        localStorage.setItem(
          "dest",
          JSON.stringify({ enable: false, goto: location.pathname })
        );
      }
    });
  }, [history]);



  useEffect(() => {
    let savedOutlet = localStorage.getItem('outlet');
    if(savedOutlet !== null){
      setOutlet(savedOutlet)
    }
  }, [])




  return (
    <>
    {isAuthenticated && outletId !== null ? <>
      <Layout auth={userState}>
          <Switch>
            <Suspense fallback={<h1>Loading...</h1>}>

              <Route exact path="/" > 
                <Dashboard outletId={outletId}  />
              </Route>
              {/* <Route
               auth={userState} path="/" exact component={Dashboard} /> */}

              <Route exact path="/performance" > 
                <Performance auth={userState} outletId={outletId} />
              </Route>

              <Route exact path="/sundry" > 
                <Sundry auth={userState} outletId={outletId} />
              </Route>


              <Route exact path="/commission" > 
                <Commission auth={userState} outletId={outletId} />
              </Route>

              <Route exact path="/add-new-emp" > 
                <AddNewEmp auth={userState} outletId={outletId} />
              </Route>

              <Route exact path="/emp-info/:id" > 
                <EmpInfo auth={userState} outletId={outletId} />
              </Route>

              <Route exact path="/income"> 
                <Income auth={userState} outletId={outletId} />
              </Route>

              <Route exact path="/expense"> 
                <Expense auth={userState} outletId={outletId} />
              </Route>

         

              <Route exact path="/sheet-generator"> 
                <Generator auth={userState} outletId={outletId} />
              </Route>

              <Route 
                path="/emp-list"
                exact >
                  <EmpList auth={userState} outletId={outletId} />
              </Route>

              <Route 
                path="/attendance"
                exact >
                  <Attendance auth={userState} outletId={outletId} />
              </Route>


              <Route 
                path="/provident"
                exact >
                  <Provident auth={userState} outletId={outletId} />
              </Route>

              <Route 
                path="/salary-sheet"
                exact >
                  <SalarySheet auth={userState} outletId={outletId} />
              </Route>



               

              <Route
               auth={userState} path="/vault" exact component={Vault} />
               
              <Route
                auth={userState}
                path="/register"
                exact
                component={Register}
              />
             
             

              <Route
              
                auth={userState}
                path="/profit-distribution"
                exact
                component={ProfitDistribution}
              />
             
              
              <Route
              
                auth={userState}
                path="/employee/update/:id"
                exact
                component={UpdateStaff}
              />

             
             

              <Route
              
                auth={userState}
                path="/payroll/distribut-salary/:id"
                exact
                component={Distribute}
              />


              
              <Route exact path="/shareholder"> 
                <Shareholder auth={userState} outletId={outletId} />
              </Route>

              <Route exact path="/investor"> 
                <Investor auth={userState} outletId={outletId} />
              </Route>

              <Route exact path="/investment/:id"> 
                <Investment auth={userState} outletId={outletId} />
              </Route>



              <Route exact path="/all-contacts"> 
                <AllContacts auth={userState} outletId={outletId} />
              </Route>

              <Route exact path="/new-user"> 
                <NewUser auth={userState} outletId={outletId} />
              </Route>
              

              <Route exact path="/opening"> 
                <Opening auth={userState} outletId={outletId} />
              </Route>
              
      
              <Route exact path="/assets"> 
                <Assets auth={userState} outletId={outletId} />
              </Route>


              <Route exact path="/income-expense"> 
                <IncomeExpense auth={userState} outletId={outletId} />
              </Route>

              <Route exact path="/attendance-report"> 
                <AttendanceReport auth={userState} outletId={outletId} />
              </Route>

              <Route exact path="/outlet-leadger"> 
                <OutletLeadger auth={userState} outletId={outletId} />
              </Route>

              <Route exact path="/date-wise-performance"> 
                <DWperformance auth={userState} outletId={outletId} />
              </Route>

              <Route exact path="/shareholder/details/:id"> 
                <ShareholderDetails auth={userState} outletId={outletId} />
              </Route>
              


              <Route
              
                path="/investment/detail/:investid"
                exact
                component={InvestmentTransection}
              />
              
              <Route
              
                auth={userState}
                path="/register-report"
                exact
                component={RegisterReport}
              />
              <Route
               auth={userState} path="/ale" exact component={Ale} />
              
              
            
              <Route
              
                auth={userState}
                path="/payments"
                exact
                component={Payments}
              />
              

              <Route
              
                auth={userState}
                path="/miscellaneous"
                exact
                component={Misc}
              />

              <Redirect path="**" to="/" />
            </Suspense>
          </Switch>
          </Layout></>:isAuthenticated ? 
          <Suspense fallback={<h1>Loading...</h1>}>
            <Outlets setOutletState={setOutlet} />
          </Suspense> 
    : <>
    <Switch>
      <Route path="/" exact component={Signin} />
      <Route path="/signup" exact component={Signup} />
      <Redirect path="**" to="/" />
    </Switch>
    </>}
  </>
  );
};

export default App;
