import React from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";

//Icons
import { AiOutlineDashboard } from "react-icons/ai";
import { FcSalesPerformance } from "react-icons/fc";
import { BsFillShieldLockFill } from "react-icons/bs";
import { GiProfit } from "react-icons/gi";
import { CgNotes } from "react-icons/cg";
import { GrUserWorker } from "react-icons/gr";
import { AiOutlinePartition } from "react-icons/ai";
import { IoIosPeople } from "react-icons/io";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { IoSettingsOutline } from "react-icons/io5";
import { RiStackshareLine } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";
import useAuth from "./../../data/useAuth";

//css design
import "./sidebar.css";

const { SubMenu } = Menu;
const { Sider } = Layout;
const rootSubmenuKeys = [
  "sub1",
  "sub2",
  "sub3",
  "sub4",
  "sub5",
  "sub6",
  "sub7",
  "sub8",
];

const Sidebar = ({ sidebarCollapse, overlayCollapse, auth }) => {
  const { isAdmin, permissions } = auth;
  const { logoutAction } = useAuth();
  //sub-menu
  const [openKeys, setOpenKeys] = React.useState(["sub1"]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const dontCallMe = () => {
    if (window.innerWidth < 600) {
      sidebarCollapse();
      overlayCollapse();
    }
  };

  const adminDashboard = (
    <Menu.Item
      key="1"
      icon={<AiOutlineDashboard />}
      onClick={() => {
        dontCallMe();
      }}
    >
      <Link to="/">Dashboard</Link>
    </Menu.Item>
  );

  const performenceSection = (
    <SubMenu key="sub1" icon={<FcSalesPerformance />} title="Performance">
      <Menu.Item
        key="2"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/performance">Accounts/Remittance</Link>
      </Menu.Item>
    </SubMenu>
  );

  const vaultSection = (
    <SubMenu key="sub2" icon={<BsFillShieldLockFill />} title="Vault">
      <Menu.Item
        key="28"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/vault">Vault</Link>
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/register">Daily Register</Link>
      </Menu.Item>
      <Menu.Item
        key="4"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/sundry"> Sundry Ledger</Link>
      </Menu.Item>
    </SubMenu>
  );

  const commissionSection = (
    <Menu.Item
      key="5"
      icon={<GiProfit />}
      onClick={() => {
        dontCallMe();
      }}
    >
      <Link to="/commission">Commission</Link>
    </Menu.Item>
  );

  const voucherSection = (
    <SubMenu key="sub3" icon={<CgNotes />} title="Voucher">
      <Menu.Item
        key="6"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/income">Income Voucher</Link>
      </Menu.Item>
      <Menu.Item
        key="7"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/expense">Expense Voucher</Link>
      </Menu.Item>
    </SubMenu>
  );

  const employeeSection = (
    <SubMenu key="sub4" icon={<GrUserWorker />} title="Employee">
      <Menu.Item
        key="8"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/add-new-emp">Add New Employee</Link>
      </Menu.Item>
      <Menu.Item
        key="9"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/emp-list">Employee List</Link>
      </Menu.Item>
      <Menu.Item
        key="11"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/provident">Provident Fund</Link>
      </Menu.Item>

      <Menu.Item
        key="13"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/attendance">Attendence</Link>
      </Menu.Item>
    </SubMenu>
  );

  const payrollSection = (
    <SubMenu key="sub5" icon={<AiOutlinePartition />} title="Payroll">
      <Menu.Item
        key="14"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/salary-sheet">Salary Sheet</Link>
      </Menu.Item>
      <Menu.Item
        key="15"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/sheet-generator">Sheet Generator</Link>
      </Menu.Item>
    </SubMenu>
  );

  const shareHolderSection = (
    <SubMenu key="sub6" icon={<RiStackshareLine />} title="Partners">
      <Menu.Item
        key="17"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/shareholder">Shareholder</Link>
      </Menu.Item>
      <Menu.Item
        key="18"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/investor">Investor</Link>
      </Menu.Item>
    </SubMenu>
  );

  const allContacts = (
    <Menu.Item
      key="19"
      icon={<IoIosPeople />}
      onClick={() => {
        dontCallMe();
      }}
    >
      <Link to="/all-contacts">All Contacts</Link>
    </Menu.Item>
  );

  const reportSection = (
    <SubMenu key="sub7" icon={<HiOutlineDocumentReport />} title="Reports">
      <Menu.Item
        key="29"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/register-report">Register Report</Link>
      </Menu.Item>

      <Menu.Item
        key="20"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/ale">Assets,Liabilities,Equity</Link>
      </Menu.Item>
      <Menu.Item
        key="21"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/date-wise-performance">Performance</Link>
      </Menu.Item>
      <Menu.Item
        key="31"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/income-expense">Income / Expense</Link>
      </Menu.Item>
      
      <Menu.Item
        key="32"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/attendance-report">Attendance Report</Link>
      </Menu.Item>

      <Menu.Item
        key="33"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/outlet-leadger">Outlet Leadger</Link>
      </Menu.Item>

    </SubMenu>
  );

  const settingSection = (
    <SubMenu key="sub8" icon={<IoSettingsOutline />} title="Settings">
      <Menu.Item
        key="22"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/new-user">New User</Link>
      </Menu.Item>
      <Menu.Item
        key="23"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/opening">ABMS Opening</Link>
      </Menu.Item>
      <Menu.Item
        key="24"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/payments">Payments</Link>
      </Menu.Item>
      <Menu.Item
        key="25"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/assets">Asstes</Link>
      </Menu.Item>
      <Menu.Item
        key="26"
        onClick={() => {
          dontCallMe();
        }}
      >
        <Link to="/miscellaneous">Miscellaneous</Link>
      </Menu.Item>
    </SubMenu>
  );

  return (
    <>
      <Sider
        style={{
          overflow: "auto",
          height: "95vh",
          left: 0,
          backgroundColor: "#fff",
        }}
      >
        {/* <div className="logo" /> */}
        <Menu
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          theme="light"
          mode="inline"
          defaultSelectedKeys={["4"]}
        >
          {isAdmin
            ? adminDashboard
            : permissions.find((x) => x === "Admin Dashboard") &&
              adminDashboard}

          {isAdmin
            ? performenceSection
            : permissions.find((x) => x === "Performance") &&
              performenceSection}

          {isAdmin
            ? vaultSection
            : permissions.find((x) => x === "Vault") && vaultSection}

          {isAdmin
            ? commissionSection
            : permissions.find((x) => x === "Commission Entry") &&
              commissionSection}

          {isAdmin
            ? voucherSection
            : permissions.find((x) => x === "Voucher") && voucherSection}

          {isAdmin
            ? employeeSection
            : permissions.find((x) => x === "Employee") && employeeSection}

          {isAdmin ? payrollSection : ""}

          {isAdmin
            ? shareHolderSection
            : permissions.find((x) => x === "Partners") && shareHolderSection}

          {isAdmin
            ? allContacts
            : permissions.find((x) => x === "Client Database") && allContacts}

          {isAdmin
            ? reportSection
            : permissions.find((x) => x === "Reports") && reportSection}
          {isAdmin
            ? settingSection
            : permissions.find((x) => x === "Settings") && settingSection}

          <Menu.Item
            key="30"
            icon={<FiLogOut />}
            onClick={() => {
              dontCallMe();
              logoutAction();
            }}
          >
            <Link to="/">Logout</Link>
          </Menu.Item>
        </Menu>
      </Sider>
    </>
  );
};

export default Sidebar;
