import React, { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "../../main.css";
import "./layout.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const Layout = ({ children, auth }) => {
	//Overlay
	const [overlay, setOverlay] = useState(false);
	const showOverlay = () => setOverlay(!overlay);

	//Sidebar Collapse
	const [sidebar, setSidebar] = useState(true);
	const showSidebar = () => setSidebar(!sidebar);

	sidebar ? disableBodyScroll(document) : enableBodyScroll(document);

	return (
		<>
			<div className="header">
				<Header auth={auth} />
				<GiHamburgerMenu
					className="open-icon"
					onClick={() => {
						showOverlay();
						showSidebar();
					}}
					cursor="pointer"
				/>
			</div>

			<div className="layout">
				<div
					className={overlay ? "overlay visible" : "overlay"}
					onClick={() => {
						showOverlay();
						showSidebar();
					}}
				/>
				<div className={sidebar ? "sidebar open" : "sidebar"}>
					<Sidebar
						auth={auth}
						sidebarCollapse={showSidebar}
						overlayCollapse={showOverlay}
					/>
				</div>

				<div className="main-content">
					<main>{children}</main>
				</div>
			</div>
		</>
	);
};

export default Layout;
