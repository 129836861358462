import { useState } from "react";
import { message } from "antd";
import { useAxios } from "./../lib/AxiosProvider";
import { useMutation, useQuery, useQueryClient } from "react-query";

const useAdmin = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();

  //Profile Create Request
  const profileCreateRequest = async (info) => {
    const { data } = await axios.post("/admin/new-profile", info);
    return data;
  };

  //Profile Fetch Request
  const profileFetchRequest = async () => {
    const { data } = await axios.get("/admin/profile");
    return data;
  };

  //$$$$-------request Handler--------$$$$$$$$
  //$$$$-------request Handler--------$$$$$$$$
  //$$$$-------request Handler--------$$$$$$$$

  const [isProfileCreated, setIsProfileCreated] = useState(false);

  //Profile Create Handler

  const { mutate: profileCreateAction, isLoading: profileCreating } =
    useMutation(profileCreateRequest, {
      onSuccess: () => {
        message.success("Profile Created Successfully");
        queryClient.invalidateQueries("admin");
        setIsProfileCreated(true);
      },
      onError: (error) => {
        message.error(error.response.data.message);
      },
    });

  //Profile Fetch Handler

  const { data: profile, isLoading: profileFetching } = useQuery(
    "admin",
    profileFetchRequest,
    { refetchOnWindowFocus: false }
  );

  return {
    profileCreateAction,
    profileCreating,
    isProfileCreated,
    setIsProfileCreated,
    profile,
    profileFetching,
  };
};

export default useAdmin;
