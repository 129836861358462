import { useState } from "react";
import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "./../lib/AxiosProvider";
import { useHistory } from "react-router-dom";
export default function useAuth() {
	const axios = useAxios();
	const queryClient = useQueryClient();
	const history = useHistory();

	const [user, setUser] = useState(null);
	const [checkAuthInBackGround, setCheckAuthInBackGround] = useState(false);
	const [depositAlert, setDepositAlert] = useState(false);

	async function authReqest(info) {
		const { data } = await axios.post("/auth", info);
		return data;
	}

	async function signupReqest(info) {
		const { data } = await axios.post("/auth/signup", info);
		return data;
	}

	async function authQueryErrorHandler() {
		return checkAuthRequest();
	}

	async function checkAuthRequest() {
		const { data } = await axios.post("/auth/verify");
		return data;
	}

	// Refetch Funcnality
	function afterAuthFetch() {
		//
	}

	const { mutate: authenticate, isLoading: authenticating } = useMutation(
		authReqest,
		{
			onSuccess: (data) => {
				const { token } = data;
				localStorage.setItem("abms_token", token);
				queryClient.setQueryData("auth", { ...data, isAuthenticated: true });
				setUser((p) => ({ user: data?.user }));
				message.success("Login successfull");
				afterAuthFetch();
			},
			onError: (err) => {
				message.error(err?.response.data.message);
			},
		}
	);

	const { mutate: backgroundAuthVerify } = useMutation(checkAuthRequest, {
		onSuccess: (data) => {
			queryClient.setQueryData("auth", { ...data, isAuthenticated: true });
			setUser((p) => ({ user: data?.user }));
			setCheckAuthInBackGround(true);
			if (data?.user?.depositAlert) {
				setDepositAlert((pre) => {
					if (pre === true) {
						return true;
					} else {
						queryClient.invalidateQueries("auth");
						return true;
					}
				});
			} else {
				setDepositAlert((pre) => {
					if (pre) {
						queryClient.invalidateQueries("auth");
						return false;
					} else {
						return false;
					}
				});
			}
		},
		onError: (err) => {
			localStorage.removeItem("abms_token");
		},
	});

	// Check auth
	const { mutate: checkAuthAction, isLoading: authChecking } = useMutation(
		checkAuthRequest,
		{
			onSuccess: (data) => {
				queryClient.setQueryData("auth", { ...data, isAuthenticated: true });
				setUser((p) => ({ user: data?.user }));
				afterAuthFetch();
				setCheckAuthInBackGround(true);
			},
			onError: (err) => {
				localStorage.removeItem("abms_token");
			},
		}
	);

	function resetQuery() {
		queryClient.removeQueries("auth", { exact: true });
	}

	function logoutAction() {
		localStorage.removeItem("outlet");
		localStorage.removeItem("abms_token");
		queryClient.setQueryData("auth", { isAuthenticated: false });
		resetQuery();
		history.push("/");
		window.location.reload();
	}

	const { mutate: signupAction, isLoading: signupLoading } = useMutation(
		signupReqest,
		{
			onSuccess: (data) => {
				queryClient.setQueryData("auth", { ...data, isAuthenticated: true });
				localStorage.setItem("abms_token", data?.token);
				// window.location.reload();
				afterAuthFetch();
			},
			onError: (err) => {
				message.error(err.response.data.message);
				localStorage.removeItem("abms_token");
			},
		}
	);

	return {
		authenticate,
		authenticating,
		checkAuthAction,
		logoutAction,
		authQueryErrorHandler,
		authChecking,
		signupAction,
		signupLoading,
		user,
		checkAuthInBackGround,
		backgroundAuthVerify,
		depositAlert,
	};
}
