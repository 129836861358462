import React, { useState, useEffect } from "react";
import "./Register.css";
import { Typography, message, Modal, Button } from "antd";

import { useAxios } from "./../../../lib/AxiosProvider";

import { useQuery } from "react-query";
import useAuth from "../../../data/useAuth";
import { useHistory } from "react-router-dom";
const Loading = () => <>Loading</>;
const RegisterComponent = () => {
	const axios = useAxios();
	const history = useHistory();
	const { signupAction, signupLoading, authQueryErrorHandler } = useAuth();
	const { data: authData } = useQuery("auth", authQueryErrorHandler, {
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (authData !== undefined) {
			try {
				const { isAuthenticated } = authData;
				if (isAuthenticated) {
					history.push("/");
				}
			} catch (error) {}
		}
	}, [authData, history]);

	// Modal state
	const [privecyModal, setPrivecyModal] = useState(false);
	const [termsAndCondition, setTermsAndCondition] = useState(false);
	// const [agree, setAgree] = useState(false);

	const agree = true;

	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [countryCode] = useState("+880");
	const [sended, setSended] = useState(false); // initially false
	// CountDown
	const [countdown, setCountdown] = useState(30);
	console.log(countdown);
	// Loading to send sms
	const [sending, setSending] = useState(false);
	const [tooManyTimeModal, setTooManyTimeModal] = useState(false);

	// After send Code Now Lets Verify And Do some another Staff
	const [inputOtp, setInputOtp] = useState("");
	const [verifing, setVerifing] = useState(false);
	const [verified, setVerified] = useState(false);

	// verified opt then password and confirm Password
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const countdownStart = () => {
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 1000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 2000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 3000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 4000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 5000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 6000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 7000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 8000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 9000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 10000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 11000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 12000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 13000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 14000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 15000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 16000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 17000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 18000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 19000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 20000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 21000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 22000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 23000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 24000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 25000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 26000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 27000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 28000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 29000);
		setTimeout(() => setCountdown((pre) => (pre = pre - 1)), 30000);
	};

	const handleNameAndPhoneSubmit = async () => {
		if (name === "") {
			return message.error("আপনার নাম লিখুন");
		}

		if (phone === "") {
			return message.error("আপনার ১১ সংখার ফোন নাম্বারটি দিন");
		}

		if (!agree) return message.error("নীতি এবং শর্তাবলির সাথে একমত হতে হবে !");
		// Check the Privacy and Policy

		var num;

		if (phone.length === 10) {
			if (countryCode === "+880") {
				num = "0" + phone;
			} else {
				num = phone;
			}
		} else {
			num = phone;
		}

		setSending(true);

		var ip = "116.206.188.14";

		try {
			const fetchIp = await fetch("https://jsonip.com", { mode: "cors" }).then(
				(res) => res.json()
			);
			ip = fetchIp.ip;
		} catch (error) {
			console.log("IP Cant Fetched ");
		}

		if (ip) {
			const { data } = await axios.post("/sms/otp", { num, ip });

			setSending(false);
			if (data.s) {
				message.success(data.e);
				setSended(true);
				countdownStart();
			}
			if (data.tooManyTime) {
				// Display Contact Info
				setTooManyTimeModal(true);
			}
			if (!data.tooManyTime && !data.s) {
				if (data.e === "user Already exist !") {
					message.error("ইতিমধ্যে এই ফোন নাম্বার দিয়ে একাউন্ট  খুলা আছে ");
				} else {
					message.error(data.e);
				}

				setSended(false);
			}
		} else {
			message.success("Your IP Address Not  or Invalid");
		}
	};

	const verifyOtp = async () => {
		// Verify Otp
		var num;
		if (phone.length === 10) {
			if (countryCode === "+880") {
				num = "0" + phone;
			} else {
				num = phone;
			}
		} else {
			num = phone;
		}

		if (inputOtp === "") {
			message.error("type Otp");
		} else if (inputOtp.length < 4) {
			message.error("type 4-digit Otp");
		} else {
			// Lets Verify
			setVerifing(true);
			const { data } = await axios.post("/sms/otp/verify", {
				otp: inputOtp,
				num: num,
			});
			setVerifing(false);
			if (data.s) {
				message.success(data.e);
				setVerified(true);
				// verified True
			} else {
				message.error(data.e);
			}
		}
	};

	const openAccount = () => {
		// Reset History Of Redirection
		localStorage.removeItem("dest");

		var num;
		if (phone.length === 10) {
			if (countryCode === "+880") {
				num = "0" + phone;
			} else {
				num = phone;
			}
		} else {
			num = phone;
		}

		if (password === "") {
			message.error("Password can't be blank");
		} else if (password.length < 6) {
			message.error("Password should be greater than six charecter");
		} else if (confirmPassword === "") {
			message.error("Re-type your password");
		} else if (password !== confirmPassword) {
			message.error("Password must be matched !");
		} else {
			// Submit for new Account
			// console.log(name, phone, password);
			signupAction({ name, phone: num, password });
		}
	};
	return (
		<>
			<div className="registration-wrapper">
				<div className="left-bg-color" />
				<h1 className="logo__register">ABMS</h1>
				<div className="dialogue">
					<div>আপনার পারসোনাল এজেন্ট ব্যাংক ম্যানেজার </div>
				</div>
				<hr />

				<div className="form-container">
					<form action="#">
						{!sended && (
							<>
								{sending && <Loading />}
								<div>
									<input
										onFocus={(e) => e.target.select()}
										onKeyPress={(e) => {
											if (e.code === "Enter") {
												handleNameAndPhoneSubmit();
											}
										}}
										value={name}
										onChange={(e) => setName(e.target.value)}
										className="register-input-field"
										placeholder="নাম"
									/>
								</div>

								<div>
									<input
										className="register-input-field"
										onKeyPress={(e) => {
											if (e.code === "Enter") {
												handleNameAndPhoneSubmit();
											}
										}}
										value={phone}
										onChange={(e) => setPhone(e.target.value)}
										placeholder="ফোন"
									/>
								</div>
								{/* 
								<div>
									<div onClick={() => {}} className="link agrement">
										<Checkbox
											onClick={() => {
												setAgree((p) => !p);
											}}
											style={{ marginRight: 10 }}
										/>{" "}
										<span>
											আমি{" "}
											<span
												// onClick={() => setPrivecyModal(true)}
												className="underline pointer"
											>
												{" "}
												গোপনীয়তা নীতি
											</span>{" "}
											এবং{" "}
											<span
												onClick={() => setTermsAndCondition(true)}
												className="underline pointer"
											>
												শর্তাবলির
											</span>{" "}
											সাথে একমত
										</span>
									</div>
								</div> */}

								<button
									onClick={handleNameAndPhoneSubmit}
									type="button"
									className={!sending ? "btn-log-in" : "btn-log-in-processing"}
									disabled={sending}
								>
									{sending ? "সেন্ডিং ও টি পি ... " : "একাউন্ট তৈরি করুন"}
								</button>

								<br />
								<div>
									<div
										onClick={() => history.push("/")}
										className="link pointer"
									>
										আপনার একাউন্ট আছে ? লগিন করুন
									</div>
								</div>
							</>
						)}

						{sended && !verified && (
							<>
								<Typography.Text style={styles.blockAndPd10}>
									Enter Your 4-digit code we sent to
								</Typography.Text>
								<Typography.Link style={styles.blockAndPd10}>
									{phone}
								</Typography.Link>

								<div>
									<input
										className="register-input-field"
										onKeyPress={(e) => {
											if (e.code === "Enter") {
												verifyOtp();
											}
										}}
										value={inputOtp}
										onChange={(e) => setInputOtp(e.target.value)}
										placeholder="ও টি পি"
									/>
								</div>

								<button
									type="button"
									onClick={verifyOtp}
									className={
										!verifyOtp ? "btn-log-in" : "btn-log-in-processing"
									}
									disabled={verifing}
								>
									{verifing ? "ভেরিফাই হচ্ছে ..." : "ভেরিফাই করুন"}
								</button>
							</>
						)}

						{sended && verified && (
							<>
								<div>
									<input
										className="register-input-field"
										type="password"
										onKeyPress={(e) => {
											if (e.code === "Enter") {
												openAccount();
											}
										}}
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										placeholder="পাসওয়ার্ড দিন"
									/>
								</div>

								<div>
									<input
										className="register-input-field"
										type="password"
										onKeyPress={(e) => {
											if (e.code === "Enter") {
												openAccount();
											}
										}}
										value={confirmPassword}
										onChange={(e) => setConfirmPassword(e.target.value)}
										placeholder="পাসওয়ার্ডটি পুনরায় দিন"
									/>
								</div>

								<button
									type="button"
									onClick={openAccount}
									className={
										signupLoading ? "btn-log-in-processing" : "btn-log-in"
									}
								>
									{signupLoading ? "সম্পূর্ণ হচ্ছে ... " : "সম্পূর্ণ করুন"}
								</button>
							</>
						)}
					</form>
				</div>

				<div className="footer">
					<a
						className="footer-link"
						target="_blank"
						rel="noreferrer"
						href="https://binarybunon.com/"
					>
						বাইনারি বুনন
					</a>
				</div>
			</div>

			{/* // TO Many Time Sent  MOdal  */}
			<Modal
				title="Notice !"
				centered
				visible={tooManyTimeModal}
				onOk={() => setTooManyTimeModal(false)}
				onCancel={() => setTooManyTimeModal(false)}
				style={styles.textCenter}
			>
				<Typography.Text>
					We have sent otp too many Times <br />
					<br />
				</Typography.Text>
				<Typography.Text>Please Contact +88017 07 801068</Typography.Text>
			</Modal>

			<Modal
				title="Privecy & Policy"
				centered
				visible={privecyModal}
				footer={[
					<Button key="button" onClick={() => setPrivecyModal(false)}>
						Close
					</Button>,
				]}
				onCancel={() => setPrivecyModal(false)}
			>
				<div className="privecy__policy">
					<p className="title">How we protect your privacy</p>
					<p>
						on <span className="red">somity.binarybunon.com</span> and when you
						use our services:
					</p>
					<p>
						BB Somity and its affiliates offer many services to help you run
						your cooperative or multipurpose business. As part of running those
						services we collect data about you and your business. This data is
						not only essential to run our services, but also critical for the
						safety of our services and all our users. This policy explains what
						information is collected, why it is collected, and how we use it.
					</p>
					<p className="title">Information we collect</p>
					<p>
						Most of the personal data we collect is directly provided by our
						users when they register and use our services. Other data is
						collected by recording interactions with our services.
					</p>
					<p>
						<strong>Customer Databases:</strong> When you subscribe to our
						software and create your own database (for example by starting a
						Free Trial), any information or content you submit or upload into
						your database is your own, and you control it fully.
					</p>
					<p>
						This data will often include personal information, for example: your
						list of employees, your contacts and customers, your messages,
						pictures etc. We only ever collect this information on your behalf,
						and{" "}
						<strong>
							you always retain ownership and full control on this data.
						</strong>
					</p>
					<p>
						<strong>Free Trial Session Recording:</strong> When you start a free
						trial on our software, you may be offered the possibility to consent
						to the recording of the beginning for your free trial session in
						order to help us improve the user experience of our products.
					</p>
					<p>
						This will likely include some personal data such as names, emails,
						phone numbers, pictures, depending on what real-world information
						you input into your database. Password fields and other sensitive
						fields are <strong>automatically excluded</strong> from the
						recording, but we cannot entirely exclude the possibility that some
						sensitive information may be recorded, depending on where you type
						it.
					</p>
					<p className="title">How we use this information</p>
					<p>
						<strong>Account & Contact Data:</strong> We use your contact
						information in order to provide our services, to answer your
						requests, and for billing and account management reasons. We may
						also use this information for marketing and communication purposes
						(our marketing messages always come with a way for you to opt-out at
						any time). We also use this data in aggregated/anonymised form in
						order to analyze service trends. If you have registered to
						participate in an event published on our website, we may transfer
						your name, email address, phone number and company name to our local
						organizer and to the sponsors of the event for both direct marketing
						purposes and in order to facilitate the preparations and booking for
						the event.
					</p>
					<p>
						<strong>Customer Database:</strong> We only collect and process this
						data on your behalf, in order to perform the services you have
						subscribed to, and based on the instructions you explicitly gave
						when you registered or configured your service.
					</p>
					<p>
						<strong>Free Trial Session Recording:</strong> The purpose of these
						recordings is to improve our products: they will be seen and
						analysed solely by our UX analyzer team, who will treat your data as
						strictly confidential information. By watching the recordings they
						will be able to see a tangible representation of a user’s first
						steps into BB Samity and improve the User Experience accordingly.
					</p>
					<p className="title">Security</p>
					<p>
						We realize how important and sensitive your personal data is, and we
						take a great number of measures to ensure that this information is
						securely processed, stored and preserved from data loss and
						unauthorized access.
					</p>
				</div>
			</Modal>

			<Modal
				title="Terms and Conditions"
				centered
				visible={termsAndCondition}
				onCancel={() => setTermsAndCondition(false)}
				footer={[
					<Button key="button" onClick={() => setTermsAndCondition(false)}>
						Close
					</Button>,
				]}
			>
				<div className="privecy__policy">
					<p className="title"> Term of the Agreement</p>

					<p>
						The duration of this Agreement (the “Term”) shall be specified in
						writing on conclusion of this Agreement, beginning on the date of
						conclusion. It is automatically renewed for an equal Term, unless
						either party provides a written notice of termination minimum 30
						days before the end of the Term to the other party.
					</p>
					<p className="title">User definition</p>
					<p>
						Any user account indicated as active in the Software, with access to
						creation and/or edition mode.
					</p>
					<p className="title">Access to the Software</p>
					<p>
						The Customer can use the Software hosted on the Cloud Platform. The
						Cloud Platform is hosted and fully managed by Binary Bunon, and
						accessed remotely by the Customer. For the duration of this
						Agreement, Binary Bunon gives the Customer a non-exclusive,
						non-transferable license to use. Binary Bunon commits not to
						disclose individual or named figures to third parties without the
						consent of the Customer, and to deal with all collected data in
						compliance with its official Privacy Policy, published at
						<a target="blank" href="https://binarybunon.com/privacy.">
							{" "}
							Binary Bunon Privacy
						</a>{" "}
						Upon expiration or termination of this Agreement, this license is
						revoked immediately and the Customer agrees to stop using the BB
						Somity software.
					</p>
					<p className="title">Services</p>
					<p>
						<strong>Bug Fixing Service </strong>
						<br />
						For the duration of this Agreement, Binary Bunon commits to making
						all reasonable efforts to remedy any Bug of the Software.
					</p>
					<p>
						<strong>Upgrade Services </strong>
						<br />
						For the duration of this Agreement, the Customer can submit upgrade
						requests.
					</p>
					<p className="title">Charges and Fees</p>
					<p>
						<strong>Standard charges</strong> <br /> The standard charges for
						the BB Samity and the Services are based on the number of Members
						added by the by the Customer. First fifty (50) members are
						absolutely free of charge. For this limit the user can use any of
						the services provided by the software. Once the limit is exceeded
						users may face some notices and limitations for some services. The
						standard charge of BB somity is One Taka only ( 1 BDT) per member
						per month. This will be applicable only if the user lists more than
						50 members.
					</p>
					<p>
						<strong>Payment Policy</strong> <br /> User has his own wallet under
						his user account in the software. You can recharge your wallet
						through any mobile banking service available in Bangladesh. You will
						be notified through mobile message or email, when your wallet
						balance is less than 50 taka.
					</p>
					<p className="title">Conditions of Services</p>
					<p>
						<strong>Customer Obligations</strong> <br />
						The Customer agrees to: <br />
						<ul>
							<li>
								pay Binary Bunon any applicable charges for the Services of the
								present Agreement, in accordance with the payment conditions
								specified at the signature of this contract ;
							</li>
							<li>
								take all measures necessary to guarantee the unmodified
								execution of the part of the Software that verifies the validity
								of the BB Somity usage;
							</li>
							<li>
								appoint 1 dedicated Customer contact person for the entire
								duration of the Agreement;
							</li>
						</ul>
					</p>
					<p>
						<strong>Publicity</strong> <br />
						<p>
							Except where notified otherwise in writing, each party grants the
							other a non-transferable, non-exclusive, royalty free, worldwide
							license to reproduce and display the other party’s name, logos and
							trademarks, solely for the purpose of referring to the other party
							as a customer or supplier, on websites, press releases and other
							marketing materials.
						</p>
					</p>
					<p className="title">Confidentiality</p>
					<p>Binary Bunon shall not</p>
					<p>
						<span> (i) </span>
						disclose to any third party the business of the Client, details
						regarding the Software Database, without limitation any information
						regarding the Software’s code, the Specifications, or the Client’s
						business (the “Confidential Information”),
					</p>
					<p>
						<span> (ii) </span>
						make copies of any Confidential Information or any content based on
						the concepts contained within the Confidential Information for
						personal use or for distribution unless requested to do so by the
						Client, or
					</p>
					<p>
						<span> (iii) </span>
						use Confidential Information other than solely for the benefit of
						the Client.
					</p>
					<p>
						<strong>6.6 Termination</strong>
					</p>
					<p>
						In the event that either Party fails to fulfill any of its
						obligations arising herein, and if such breach has not been remedied
						within 30 calendar days from the written notice of such breach, this
						Agreement may be terminated immediately by the non-breaching Party.
					</p>
					<p>
						Further, Binary Bunon may terminate the Agreement immediately in the
						event the Customer fails to pay the applicable fees for the Services
						within 30 days following the due date specified on the corresponding
						invoice, and after minimum 3 reminders.
					</p>
					<p className="title">Warranties, Disclaimers, Liability</p>
					<p>
						<strong>Warranties</strong>
					</p>
					<p>
						Binary Bunon owns the copyright or an equivalent 1 on 100% of the
						code of the Software.
					</p>
					<p>
						<strong>Disclaimers</strong>
					</p>
					<p>
						Except as expressly provided herein, neither party makes any
						warranty of any kind, whether express, implied, statutory or
						otherwise, and each party specifically disclaims all implied
						warranties, including any implied warranty of merchantability,
						fitness for a particular purpose or non-infringement, to the maximum
						extent permitted by applicable law.
					</p>
					<p>
						Binary Bunon does not warrant that the Software complies with any
						local or international law or regulations.
					</p>
				</div>
			</Modal>
		</>
	);
};

export default RegisterComponent;

const styles = {
	container: {
		width: "100%",
		height: "80vh",
		display: "grid",
		placeContent: "center",
	},

	textCenter: {
		textAlign: "center",
	},
	card: {
		minWidth: "400px",
	},
	pb20: {
		paddingBottom: "20px",
	},
	blockAndPd10: {
		display: "block",
		paddingBottom: "10px",
	},
	mr10: {
		marginRight: "10px",
	},
	textRight: {
		textAlign: "right",
	},
};
