import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
import { Typography, message, Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import useAuth from "../../../data/useAuth";
import "./Login.css";
import { useAxios } from "./../../../lib/AxiosProvider";
const Login = ({ isMobile }) => {
  // Check if dest not empty enable it
  const axios = useAxios();
  // const history = useHistory();
  const {
    authenticate,
    authenticating,
    checkAuthAction,
    authChecking,
    checkAuthInBackGround,
  } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem("abms_token");
    if (token !== null) {
      checkAuthAction();
    }
  }, [checkAuthAction]);

  useEffect(() => {
    (() => {
      let dest = localStorage.getItem("dest");
      if (dest !== null) {
        try {
          let destData = JSON.parse(dest);
          destData.enable = true;
          localStorage.setItem("dest", JSON.stringify(destData));
        } catch (error) {
          localStorage.removeItem("dest");
        }
      }
    })();
  }, []);

  const [resetSection, setResetSection] = useState(false);
  const [optSending, setOptSending] = useState(false);
  const [tooManyTimeModal, setTooManyTimeModal] = useState(false);
  const [sended, setSended] = useState(false); // initially false

  const [lavel, setLavel] = useState("admin");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  // set Verifying Loading
  const [verifing, setVerifing] = useState(false);
  const [verified, setVerified] = useState(false);

  // Otp For Verification
  const [inputOtp, setInputOtp] = useState("");
  const [changing, setChanging] = useState(false);
  const [changed, setChanged] = useState(false);

  // verified opt then password and confirm Password
  const [resetPassword, setResetPassword] = useState("");
  const [resetConfirmPassword, setResetConfirmPassword] = useState("");

  const LoginHandler = () => {
    if (lavel === "") {
      message.error("এডমিন না স্টাফ নির্বাচন করুন");
    } else if (phone === "") {
      message.error("ফোন নাম্বার লিখুন");
    } else if (password === "") {
      message.error("পাসওয়ার্ড দিন");
    } else {
      // login

      // Reset Dest History From
      localStorage.removeItem("dest");

      // dispatch(signInAction({ lavel, phone, password }));
      authenticate({ lavel, phone, password });
    }
  };

  const sendOtp = async () => {
    setOptSending(true);
    const fetchIp = await fetch("https://jsonip.com", { mode: "cors" }).then(
      (res) => res.json()
    );
    if (fetchIp.ip) {
      const { data } = await axios.post("/sms/otp/reset", {
        phone,
        ip: fetchIp.ip,
        lavel,
      });
      setOptSending(false);
      if (data.s) {
        message.success(data.e);
        setSended(true);
      }
      if (data.tooManyTime) {
        // Display Contact Info
        setTooManyTimeModal(true);
      }
      if (!data.tooManyTime && !data.s) {
        message.error(data.e);
        setSended(false);
      }
    }
  };

  const VerifiyOtp = async () => {
    // Verify Otp
    if (inputOtp === "") {
      message.error("চার ডিজিটের ও টি পি দিন");
    } else if (inputOtp.length < 4) {
      message.error("চার ডিজিটের ও টি পি দিন");
    } else {
      // Verify Otp
      setVerifing(true);
      const { data } = await axios.post("/sms/otp/reset/verify", {
        phone,
        otp: inputOtp,
      });
      setVerifing(false);
      if (data.s) {
        message.success(data.e);
        setVerified(true);
        // verified True
      } else {
        message.error(data.e);
      }
    }
  };

  const changePassword = async () => {
    if (resetPassword === "") {
      message.error("নতুন পাসওয়ার্ড দিন ");
    } else if (resetConfirmPassword === "") {
      message.error("নতুন পাসওয়ার্ডটি পুনরায় দিন ");
    } else if (resetPassword !== resetConfirmPassword) {
      message.error("পাসওয়ার্ড দুটি মিলে নি !");
    } else {
      // do staffs

      try {
        setChanging(true);
        await axios.post("/auth/reset", {
          lavel,
          phone,
          password: resetPassword,
        });
        setChanging(false);
        setChanged(true);
        message.success("পাসওয়ার্ড সফলভাবে পরিবর্তন হয়েছে ");
      } catch (error) {
        setChanged(false);
        message.error(error?.response?.data?.message);
      }
    }
  };

  //

  if (authChecking && !checkAuthInBackGround) {
    const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin indicator={antIcon} />
      </div>
    );
  }

  return (
    <>
      <div className="login__wrapper">
        <div className="left-bg-color" />
        <h1 className="logo__login">ABMS</h1>
        <div className="dialogue">
          <div>আপনার পারসোনাল এজেন্ট ব্যাংক ম্যানেজার</div>
          {/* <div className="__free_account_mobile_button__container">
            <button
              type="button"
              onClick={() => history.push("/signup")}
              className="btn __free_account_mobile_button pointer"
            >
              ফ্রি একাউন্ট তৈরি করুন !
            </button>
          </div> */}
        </div>
        {/* <br />
        <div className="cursor">
          <button
            type="button"
            onClick={() => history.push("/signup")}
            className="btn start-for-free-registration pointer"
          >
            ফ্রি একাউন্ট তৈরি করুন !
          </button>
        </div> */}

        <div className="login__form-container">
          {!resetSection ? (
            <form>
              <div className="login__access">
                <button
                  onClick={() => {
                    setLavel("admin");
                  }}
                  className={lavel === "admin" ? "fill" : "outline"}
                  type="button"
                >
                  এডমিন
                </button>
                <button
                  onClick={() => {
                    setLavel("staff");
                  }}
                  className={lavel === "staff" ? "fill" : "outline"}
                  type="button"
                >
                  স্টাফ
                </button>
              </div>
              <div>
                <input
                  className="login-input-field"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  id="emailOrPhone"
                  placeholder="ফোন"
                  type="number"
                />
              </div>
              <div>
                <input
                  className="login-input-field"
                  value={password}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      LoginHandler();
                    }
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  type="password"
                  placeholder="পাসওয়ার্ড"
                />
              </div>
              <button
                disabled={authenticating}
                onClick={LoginHandler}
                type="button"
                className="btn-log-in"
              >
                {authenticating ? "প্রবেশের চেস্টা চলছে ..." : " প্রবেশ করুন"}
              </button>
              <br />
              <div>
                <div
                  onClick={() => setResetSection(true)}
                  className="link pointer"
                >
                  পাসওয়ার্ড মনে নেই?
                </div>
              </div>
            </form>
          ) : !sended ? (
            <form>
              <div className="login__access">
                <button
                  onClick={() => {
                    setLavel("admin");
                  }}
                  className={lavel === "admin" ? "fill" : "outline"}
                  type="button"
                >
                  এডমিন
                </button>
                <button
                  onClick={() => {
                    setLavel("staff");
                  }}
                  className={lavel === "staff" ? "fill" : "outline"}
                  type="button"
                >
                  স্টাফ
                </button>
              </div>
              <div>
                <input
                  className="login-input-field"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  id="emailOrPhone"
                  placeholder="ফোন"
                  type="number"
                />
              </div>
              <div className="__custom_btn_login_wrapper">
                <button type="button" onClick={() => setResetSection(false)}>
                  বাতিল করুন
                </button>
                <button type="button" onClick={sendOtp}>
                  {optSending ? "ও টি পি পাঠানো হচ্ছে ..." : "ও টি পি পাঠান"}
                </button>
              </div>
            </form>
          ) : !verified ? (
            <form>
              <p>Enter Your 4-digit code we sent to</p>
              <p> {phone}</p>
              <input
                className="login-input-field"
                value={inputOtp}
                onChange={(e) => setInputOtp(e.target.value)}
                id="emailOrPhone"
                placeholder="ও টি পি"
                type="number"
              />
              <br />

              <div className="__custom_btn_login_wrapper">
                <button
                  type="button"
                  onClick={() => {
                    setResetSection(false);
                    setSended(false);
                  }}
                >
                  বাতিল করুন
                </button>
                <button type="button" onClick={VerifiyOtp}>
                  {verifing ? "ভেরিফাইয়ের চেস্টা চলছে ..." : " চালিয়ে যান"}
                </button>
              </div>
            </form>
          ) : !changed ? (
            <form>
              <div>
                <input
                  className="login-input-field"
                  type="password"
                  value={resetPassword}
                  onChange={(e) => setResetPassword(e.target.value)}
                  placeholder="পাসওয়ার্ড দিন"
                />
              </div>

              <div>
                <input
                  className="login-input-field"
                  type="password"
                  value={resetConfirmPassword}
                  onChange={(e) => setResetConfirmPassword(e.target.value)}
                  placeholder="পাসওয়ার্ডটি পুনরায় দিন"
                />
              </div>
              <button
                disabled={changing}
                onClick={changePassword}
                type="button"
                className="btn-log-in"
              >
                {changing
                  ? "পাসওয়ার্ড পরিবর্তন হচ্ছে ..."
                  : "পাসওয়ার্ড পরিবর্তন করুন"}
              </button>
            </form>
          ) : (
            <form>
              <div className="__custom_btn_login_wrapper">
                <p className="title">আপনার পাসওয়ার্ড পরিবর্তন সফল হয়েছে </p>
                <div className>
                  <button
                    onClick={() => {
                      setResetSection(false);
                      setChanged(false);
                      setSended(false);
                      setVerified(false);
                    }}
                    type="button"
                  >
                    লগিন করুন
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
        <div className="footer">
          <a
            className="footer-link"
            target="_blank"
            rel="noreferrer"
            href="https://binarybunon.com/"
          >
            বাইনারি বুনন
          </a>
        </div>
      </div>

      <Modal
        title="Notice !"
        centered
        visible={tooManyTimeModal}
        onOk={() => setTooManyTimeModal(false)}
        onCancel={() => setTooManyTimeModal(false)}
        style={styles.textCenter}
      >
        <Typography.Text>
          We have sent otp too many Times <br />
          <br />
        </Typography.Text>
        <Typography.Text>Please Contact +88017 07 801068</Typography.Text>
      </Modal>
    </>
  );
};

export default Login;

const styles = {
  container: {
    width: "100%",
    height: "80vh",
    display: "grid",
    placeContent: "center",
  },

  textCenter: {
    textAlign: "center",
  },
};
